import { BackIcon, BodyWrapper, LabelElement } from 'components';
import { EmailVerification } from './components';

interface IVerifyEmail {
	moveToEmailScreen: () => void;
	verificationStatus: string;
}

export const VerifyEmail: React.FC<IVerifyEmail> = ({
	moveToEmailScreen,
	verificationStatus,
}) => {
	const handleGoBack = () => {
		moveToEmailScreen();
	};
	return (
		<BodyWrapper
			optionalClassName="VerifyEmail--container"
			label={
				<div className="OtpVerify--formHeader">
					<BackIcon handleGoBack={handleGoBack} />
					<LabelElement text="Email Verification" />
				</div>
			}
			bodyElement={
				<EmailVerification verificationStatus={verificationStatus} />
			}
		/>
	);
};
