import { Button, Loader, ReactDropdown, SkeltonLoader } from '@storybook';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	CameraOptions,
	activeScreenState,
	selectedCameraLabel,
	selectedDeviceIdState,
} from 'views/facial-enroll/states';
import {
	LeftRight_URL,
	StepMobileVerifiactionMessage,
	StepVerifiactionMessage,
} from './constants/message';
import './liveness-information.scss';
import { IOptionType } from 'views/kyb/stores';
import { useSharedVariables, useWindowWidth } from 'hooks';
import { IsWebRtcConnectionEnabledState } from '../web-rtc';
import { BetterPhotoTips } from 'components';
import { FacialQr } from '../facial-qr';
import { useQrPage } from 'views/qr-page';
import { isDesktopDevice } from 'utils/device';

const optionCss = {
	baseStyle: {
		display: 'flex',
		alignItems: 'center', // Align items to the start vertically
		justifyContent: 'flex-start',
		paddingTop: 6,
	},
	hoverStyle: {
		borderRadius: 8,
		transform: 'scale(0.98)', // Slightly reduce the size
	},
};

export const LivenessInformation = () => {
	const setActiveScreen = useSetRecoilState(activeScreenState);
	const [click, setClick] = useState(false);
	const livenessVideoRef = useRef<HTMLVideoElement | null>(null);

	const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
	const SetselectedDeviceIdState = useSetRecoilState(selectedDeviceIdState);
	const SetselectedCameraLabel = useSetRecoilState(selectedCameraLabel);
	const [valueCamera, setValueCamera] = useState<any>();
	const [deFaultvalueCamera, setdeFaultValueCamera] = useState<IOptionType>();
	const [cameraOptions, setCameraOptions] = useRecoilState(CameraOptions);
	const [loading, setLodaing] = useState<boolean>(true);
	const [IsWebRtcConnectionEnable, setIsWebRtcConnectionEnabled] =
		useRecoilState(IsWebRtcConnectionEnabledState);

	const [isFaceAppVisible, setIsFaceAppVisible] = useState(true);

	// Extract specific variables from hooks for handling desktop continuation logic and authentication QR generation
	const { continueWithDesktop } = useQrPage();
	const { getAuthQr } = useSharedVariables();

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				// Trigger a re-render by changing the state
				setIsFaceAppVisible(prev => !prev);
			}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setIsWebRtcConnectionEnabled(true);
		}, 8000);
	}, [setIsWebRtcConnectionEnabled, isFaceAppVisible]);

	const { isMobile } = useWindowWidth();

	useEffect(() => {
		const fetchDevices = async () => {
			try {
				const devices = await navigator.mediaDevices.enumerateDevices();
				const videoDevices = devices.filter(
					device => device.kind === 'videoinput'
				);
				setCameras(videoDevices);
				let frontCameras = videoDevices.filter(device =>
					device.label.toLowerCase().includes('front')
				);
				if (frontCameras.length === 0 && videoDevices.length > 0) {
					frontCameras = videoDevices; // If no front cameras, use all available video input devices
				}
				if (frontCameras.length > 0) {
					SetselectedDeviceIdState(frontCameras[0]?.deviceId ?? '');
					SetselectedCameraLabel(frontCameras[0]?.label ?? '');

					const defaultValueObject = {
						value: frontCameras[0]?.deviceId ?? '',
						label: frontCameras[0]?.label ?? '',
					};

					setdeFaultValueCamera(defaultValueObject);
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error('Error fetching devices:', error);
			}
		};

		fetchDevices();
	}, [SetselectedCameraLabel, SetselectedDeviceIdState, isFaceAppVisible]);

	useEffect(() => {
		const getCameraOptions = () => {
			const filteredCameras = cameras.filter(device => {
				const label = device.label.toLowerCase();
				return (
					(label.includes('facetime') || label.includes('front')) &&
					!label.includes('desk view')
				);
			});

			const displayCameras =
				filteredCameras.length > 0 ? filteredCameras : cameras;

			const options = displayCameras.map(device => ({
				value: device.deviceId,
				label: device.label || `Camera ${cameras.indexOf(device) + 1}`,
			}));

			setCameraOptions(options);
		};
		getCameraOptions();
	}, [cameras, setCameraOptions, isFaceAppVisible]);

	const handleCameraChange = (selectedOption: IOptionType) => {
		SetselectedDeviceIdState(selectedOption.value);
		SetselectedCameraLabel(selectedOption.label);
		setValueCamera(selectedOption);
	};

	const handleOnClick = () => {
		// Trigger desktop flow if on desktop and auth QR is unavailable.
		if (isDesktopDevice() && !getAuthQr) {
			continueWithDesktop();
		}
		setClick(prevClick => !prevClick);
		setActiveScreen('facial-enroll');
	};

	const { HEADING, SUBHEADING, ...TipsStepsMessage } = useMemo(() => {
		if (isMobile) return StepMobileVerifiactionMessage;
		return StepVerifiactionMessage;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile, isFaceAppVisible]);

	// Conditionally render the FacialQr component if on desktop and auth QR is unavailable
	const renderQr = useMemo(() => {
		if (!isMobile && isDesktopDevice() && !getAuthQr) {
			return <FacialQr />;
		}
		return <></>; // Return an empty fragment by default
	}, [getAuthQr, isMobile]);

	return (
		<div className="LivenessInformation--container">
			<div className="LivenessInformation--container__content-container">
				<div className="LivenessInformation--container__inner">
					<div className="LivenessInformation--container__content">
						<h2 className="LivenessInformation--container__content__heading">
							{HEADING}
						</h2>
						<p className="LivenessInformation--container__content__subheading">
							{SUBHEADING}
						</p>
						<BetterPhotoTips content={TipsStepsMessage} />

						<div className="LivenessInformation__button--info LivenessInformation__button-begin-liveness">
							<div style={{ width: '100%' }}>
								{!IsWebRtcConnectionEnable && (
									<div className="LivenessInformation__webrtc--loader">
										Please wait while we establish a secure connection.
									</div>
								)}
								<div className="camera-dropdown-container">
									{deFaultvalueCamera && cameraOptions.length > 0 && (
										<>
											<div className="camera-icon-box">
												<i className="ri-camera-fill camera-icon"></i>
											</div>
											<ReactDropdown
												options={cameraOptions}
												isRequired
												value={valueCamera}
												handleChangeSelect={handleCameraChange}
												defaultValue={deFaultvalueCamera}
												dropDownStyle={{
													borderRadius: '10px',
													border: 'unset',
												}}
												menuPlacement="top"
												optionDropDownStyle={optionCss}
												containerWidth="100%"
											/>
										</>
									)}
								</div>
								<Button
									label={
										!IsWebRtcConnectionEnable ? (
											<Loader type="loader" dimension={18} />
										) : (
											'Begin Liveness Verification'
										)
									}
									type="button__filled button__filled--primary button__large button__block "
									handleClick={handleOnClick}
									loader={
										click ? <Loader type="loader" dimension={18} /> : <></>
									}
									disabled={!IsWebRtcConnectionEnable}
								/>
							</div>
						</div>
						{renderQr}
					</div>

					<div className="LivenessInformation--container__image-wrapper-mobile">
						{loading && <SkeltonLoader width="100%" height="100%" />}
						<video
							ref={livenessVideoRef}
							className="LivenessInformation--container__imageinfo"
							autoPlay
							preload="auto"
							loop
							muted
							controls={false}
							playsInline
							onLoadedData={() => setLodaing(false)}
						>
							<source src={LeftRight_URL} type="video/mp4" />
						</video>
					</div>
				</div>
			</div>

			{/* Video for desktop view */}
			<div className="LivenessInformation--container__image-wrapper">
				{loading && <SkeltonLoader width="100%" height="100%" />}
				<video
					ref={livenessVideoRef}
					className="LivenessInformation--container__imageinfo"
					autoPlay
					preload="auto"
					loop
					muted
					controls={false}
					playsInline
					onLoadedData={() => setLodaing(false)}
				>
					<source src={LeftRight_URL} type="video/mp4" />
				</video>
			</div>
		</div>
	);
};
