import { FC, useEffect, useMemo } from 'react';
import { Image } from '@storybook';

import { QrLoader } from '../qr-loader';
import { QrSuccessHeader } from '../../constant';
import { IQrVerificationTypes, useQrPage } from '../../stores';

import './qr-success.scss';

interface IQrSuccessHeaderItem {
	title: string;
	description: string;
	loadingMessage: string;
	image: string;
	icon?: string;
}

interface IQrSuccess {
	// Optional status prop to handle different states (close, redirect, reload, in-process, terminated)
	status?: 'close' | 'redirect' | 'reload' | 'in-process' | 'terminated';
	// Verification type determines which QR verification flow to display (e.g., 'auth' or 'kyc')
	verificationType: IQrVerificationTypes;
}

// Functional component to handle the QR success state and related actions
export const QrSuccess: FC<IQrSuccess> = ({
	status = 'close',
	verificationType,
}) => {
	// Memoize the title, description, and image based on the verification type and status
	// Recomputes values only when either status or verificationType changes
	const {
		title,
		description,
		image,
		icon,
		loadingMessage = '',
	} = useMemo(
		() =>
			(QrSuccessHeader[verificationType]?.[status] ??
				{}) as IQrSuccessHeaderItem,
		[status, verificationType]
	);

	// Extract the `handleRedirectToNext` function from the `useQrPage` hook for managing the redirection process.
	const { handleRedirectToNext } = useQrPage();

	// UseEffect hook to handle the redirection process when status is 'redirect'
	// It initiates redirection after a 5-second delay
	useEffect(() => {
		if (status === 'redirect') {
			setTimeout(() => handleRedirectToNext(), 5000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="Qr__Success--wrapper">
			{/* Success icon section: Displays an animated success icon */}

			{icon && (
				<div className="Qr__Success--icon-wrapper">
					<i className={`qr-success-icon ${icon}`} />
				</div>
			)}
			{image && (
				<div className="Qr__Success--image-wrapper">
					<Image fileName={image} width="200" />
				</div>
			)}

			{/* Title and description section: Displays the relevant text based on the current status */}
			<div className="Qr__Success--details">
				<div className="Qr__Success--details_title">{title}</div>
				<div className="Qr__Success--details_description">{description}</div>
			</div>

			{/* Footer section: Displays a loader or other message based on the status */}
			<div className="Qr__Success--footer">
				{status === 'redirect' && <QrLoader description={loadingMessage} />}
			</div>
		</div>
	);
};
