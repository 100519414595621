import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LivenessInformation } from './components/liveness-information';
import {
	activeScreenState,
	cameraPermissionDisplayMessage,
	isCameraPermission,
} from './states';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FaceEnroll } from './components/facial-enroll';
import { SelfieDetailPage } from './components/selfie-detail/selfie-detail';
import { CameraSettings } from 'views/camera-settings';
import { Loader, Modal } from '@storybook';
import { CameraPermisionsErrors } from './constant';
import { MainpalmEnrollment } from 'views/palm-enroll/main-palmEnrollment';
import { WebComponentMetaDataState } from 'states';
import { useFaceRecognitionRequests } from './hooks/hooks';
import useOrientation from 'utils/detect-oriantation';
import { THE_KYB_PROVIDER_TOKEN } from 'views/kyb/constants';
import useFetchWithToken from 'hooks/use-fetch-with-token/use-fetch-with-token';
import { useSharedVariables } from 'hooks';
import { APIS } from 'constants/api';
import { CountryStateListState } from 'views/kyb/stores';
import { useConnectionSpeedCheck } from 'views/internet-connection-speed/hooks/useConnectionSpeedCheck';
import { QrVerification, useQrPage } from 'views/qr-page';

export const FaceMain = () => {
	const activeScreen = useRecoilValue(activeScreenState);
	const webComponentMetaData = useRecoilValue(WebComponentMetaDataState);
	const [countryList, setCountryList] = useRecoilState(CountryStateListState);
	const [cameraPermission, setCameraPermission] = useRecoilState<
		boolean | null
	>(isCameraPermission);
	const orientation = useOrientation();
	const [cameraPermissionMessage, setCameraPermissionMessage] =
		useRecoilState<string>(cameraPermissionDisplayMessage);
	const { submitFaceRecognition } = useFaceRecognitionRequests();
	const { fetchData } = useFetchWithToken();
	const { envHost } = useSharedVariables();
	const checkInternetSpeed = useConnectionSpeedCheck();
	const isCountryDataFetched = useRef(false);

	// Extract the `handleCreateConnection` and `renderQrPages` functions from the `useQrPage` custom hook.
	// These functions are responsible for managing QR page interactions and establishing connections.
	const { handleCreateConnection, renderQrPages } = useQrPage();

	const { biometric = true } = webComponentMetaData ?? {};
	const [loader, setLoader] = useState(true);

	const fetchCountryData = useCallback(async () => {
		const { data } = await fetchData(
			APIS.COUNTRY_LIST,
			THE_KYB_PROVIDER_TOKEN[envHost as keyof typeof THE_KYB_PROVIDER_TOKEN]
		);
		setCountryList(data);
	}, [envHost, fetchData, setCountryList]);

	useEffect(() => {
		// Establishes a connection and assigns the event listener for handling events
		handleCreateConnection();

		/**
		 * Event handler for the 'beforeunload' event to warn users before closing or refreshing the page.
		 */
		const handleBeforeUnload = (e: BeforeUnloadEvent) => {
			e.preventDefault(); // Necessary to trigger the confirmation dialog
			e.returnValue = ''; // Ensures the dialog appears in certain browsers
		};

		// Adds the 'beforeunload' event listener to the window
		window.addEventListener('beforeunload', handleBeforeUnload);

		// Cleanup function to remove the event listener when the component unmounts
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
		// Dependencies intentionally left empty to ensure this runs only once during the component lifecycle
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//@avinashSathschel we have hided facial biometric for liquidity
	const { isSkipAuthVariable } = useSharedVariables();
	useEffect(() => {
		checkInternetSpeed();
		if (isSkipAuthVariable?.skipAuth)
			submitFaceRecognition(isSkipAuthVariable?.image ?? '').finally(() => {
				setLoader(false);
			});
		else {
			setLoader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSkipAuthVariable]);

	useEffect(() => {
		if (!countryList?.length && !isCountryDataFetched.current) {
			fetchCountryData();
			isCountryDataFetched.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryList]);

	useEffect(() => {
		if (!biometric) {
			submitFaceRecognition();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [biometric]);

	useEffect(() => {
		const checkCameraPermission = () => {
			navigator.mediaDevices
				.getUserMedia({ video: true })
				.then(stream => {
					// Camera permission is granted
					setCameraPermission(true);
					// Stop the stream immediately
					stream.getTracks().forEach(track => track.stop());
				})
				.catch(err => {
					// Camera permission is not granted
					if (err.name === 'NotReadableError') {
						// Handling hardware camera issue here
						setCameraPermissionMessage(
							CameraPermisionsErrors.Not_Readable_Error
						);
						// Handle 'Device in use' error
					} else if (err.message === 'Device in use') {
						// Handling device in  use case
						setCameraPermissionMessage(CameraPermisionsErrors.Device_in_use);
					} else {
						setCameraPermissionMessage(
							CameraPermisionsErrors.Camera_settings_OFF
						);
					}
					setCameraPermission(false);

					// Retry after a short delay
					setTimeout(checkCameraPermission, 1000); // Retry after 1 second
				});
		};
		if (biometric && !isSkipAuthVariable?.skipAuth) {
			checkCameraPermission(); // Initial permission check
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		biometric,
		isSkipAuthVariable,
		setCameraPermission,
		setCameraPermissionMessage,
	]);

	// Memoize QR content, recomputing only when `renderQrPages` changes.
	const qrContent = useMemo(
		() => renderQrPages(QrVerification.Auth),
		[renderQrPages]
	);

	const renderMainComponent = useMemo(() => {
		// Return the QR content if it is not null.
		if (qrContent !== null) {
			return qrContent;
		}

		switch (activeScreen) {
			case 'liveness-information':
				return <LivenessInformation />;
			case 'facial-enroll':
				return <FaceEnroll />;
			case 'selfie-screen':
				return <SelfieDetailPage />;
			case 'palm-print':
				return <MainpalmEnrollment />;
			default:
				return <></>;
		}
	}, [qrContent, activeScreen]);

	const renderModal = useMemo(() => {
		return (
			<Modal
				showCloseBtn={false}
				className="full-screen-modal"
				isOpen={orientation === 'landscape'}
				modalName={''}
				optionalClassName="rotate-modal"
			>
				<div className="rotate-modal--body-wrapper">
					<div className="rotate-logo">
						<i className="ri-rotate-lock-fill"></i>
						<div className="rotate-logo-desp">
							Rotate your phone to portrait mode for the best experience.
						</div>
					</div>
				</div>
			</Modal>
		);
	}, [orientation]);

	const renderComponent = useMemo(() => {
		if (cameraPermission === null) {
			return (
				<div className="FacialLoader">
					<Loader />
				</div>
			);
		} else if (!cameraPermission) {
			return <CameraSettings cameraTypeError={cameraPermissionMessage} />;
		} else return renderMainComponent;
	}, [cameraPermission, cameraPermissionMessage, renderMainComponent]);

	if (loader) {
		return (
			<div className="FacialLoader">
				<Loader />
			</div>
		);
	}

	return (
		// <div>{orientation === 'landscape' ? renderModal : renderComponent}</div>
		<>
			{renderModal} {renderComponent}
		</>
	);
};
