import { Loader } from '@storybook';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
	PlaidLinkOnExit,
	PlaidLinkOnSuccess,
	PlaidLinkOptions,
	usePlaidLink,
} from 'react-plaid-link';

import { useNextStep, useNotification, useSharedVariables } from 'hooks';
import { FundNameState } from 'hooks/use-next-step/stores';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isShowSkipState } from 'states';
import { BankDetails, ConnectBank, FundPaySDK, UnitPrice } from '../components';
import {
	CONNECT_BANK,
	MAX_DEFAULT_AMOUNT,
	MESSAGE,
	SESSION_TYPE,
	WIRE_LOADING_MESSAGE,
} from '../constants';
import {
	IsInvestingAmountEmptyState,
	PayInUnitPricingState,
	SelectedPaymentMethodState,
	useFundInvestmentRequests,
} from '../stores';

export const PayIn = () => {
	const [loader, setLoader] = useState(false);
	const [showConnectBank, setShowConnectBank] = useState(true);
	const [token, setToken] = useState(null);
	const [message, setMessage] = useState('');
	const setIsShowSkip = useSetRecoilState(isShowSkipState);
	const fundNameState = useRecoilValue(FundNameState);
	const setPayInUnitPricing = useSetRecoilState(PayInUnitPricingState);
	const setIsInvestingAmountEmpty = useSetRecoilState(
		IsInvestingAmountEmptyState
	);
	const [isCardDetailsShow, setIsCardDetailsShow] = useState(false);
	const selectedPaymentMethod = useRecoilValue(SelectedPaymentMethodState);

	const selectedPaymentMethodRef = useRef(selectedPaymentMethod);

	useEffect(() => {
		selectedPaymentMethodRef.current = selectedPaymentMethod;
	}, [selectedPaymentMethod]);

	// hooks
	const { sessionPayloadDetail, payInUnitPricing } = useNextStep();
	const { onboardingType } = useSharedVariables();

	const { investingAmount, fundName, sessionType } = useMemo(
		() => sessionPayloadDetail ?? {},
		[sessionPayloadDetail]
	);
	const { headerTitle, headerSubtitle } =
		CONNECT_BANK[sessionType as SESSION_TYPE.Seller] ?? {};

	const {
		_id: pipelineId,
		stepsId,
		userId,
		currentAction,
	} = useMemo(() => sessionPayloadDetail ?? {}, [sessionPayloadDetail]);

	const isSeller = sessionType === SESSION_TYPE.Seller;
	const isBuyer = sessionType === SESSION_TYPE.Buyer;

	const initialUnitPricingValue = useMemo(
		() => !isSeller && !isBuyer && !!payInUnitPricing,
		[isBuyer, isSeller, payInUnitPricing]
	);

	const [isPerUnitPrice, setIsPerUnitPrice] = useState(initialUnitPricingValue);

	const { errorNotification } = useNotification();

	const { createToken, linkAccount, fetchBankDetails, makeWirePayment } =
		useFundInvestmentRequests();

	//sending institution name and public token to backend once user will cliked on Success screen
	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			const sucessPayload = {
				pipelineId: pipelineId ?? '',
				stepId: stepsId,
				userId,
				actions: [
					{
						id: 'fundInvestmentTokenExchange',
						data: {
							token: publicToken,
							institutionName: metadata.institution?.name,
							linkToken: token,
						},
					},
				],
			};
			setMessage(MESSAGE.LINKING_ACCOUNT);
			setToken(null);
			setLoader(true);
			await linkAccount(sucessPayload);
			setMessage(MESSAGE.FETCHING_BANK_DETAILS);
			await fetchBankDetails();
			setShowConnectBank(false);
			setToken(null);
			setLoader(false);
			setMessage('');
		},

		[pipelineId, stepsId, userId, token, linkAccount, fetchBankDetails]
	);

	//not required for now keep it for future use
	const onExit = useCallback<PlaidLinkOnExit>(() => {
		setLoader(false);
		setToken(null);
	}, []);

	const handleBack = useCallback(() => {
		setShowConnectBank(true);
	}, []);

	//plaid configuration
	const config: PlaidLinkOptions = {
		token,
		onSuccess,
		onExit,
	};

	// will be used in future as this is not required for now ready, error, exit , for now open is required only
	const { open } = usePlaidLink(config);

	const handleSuccessOnCreateToken = useCallback((data: any) => {
		setToken(data?.token);
	}, []);

	const handleOnFailedCreateToken = useCallback(() => {
		errorNotification('Something Went Wrong');
		setIsShowSkip(true);
	}, [errorNotification, setIsShowSkip]);

	const handleConnect = useCallback(async () => {
		if (selectedPaymentMethodRef.current !== 'plaid') {
			setIsCardDetailsShow(true);
			return;
		}
		setLoader(true);
		if (onboardingType === 'complex') {
			/**
			 * yaha pe token set karna hai in the local state
			 * */
			setToken(currentAction?.token);
			return;
		}
		createToken(handleSuccessOnCreateToken, handleOnFailedCreateToken);
	}, [
		createToken,
		currentAction?.token,
		handleOnFailedCreateToken,
		handleSuccessOnCreateToken,
		onboardingType,
	]);

	useEffect(() => {
		if (isBuyer && parseFloat(investingAmount) >= MAX_DEFAULT_AMOUNT) {
			makeWirePayment();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (token) open();
	}, [open, token]);

	useEffect(() => {
		if (initialUnitPricingValue) {
			setPayInUnitPricing(pre => ({ ...pre, pricePerUnit: payInUnitPricing }));
			setIsInvestingAmountEmpty(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialUnitPricingValue]);

	const handleBackToUnitPrice = useCallback(() => setIsPerUnitPrice(true), []);

	const renderInvestmentDetails = useMemo(
		() => (
			<div className="fi-connect-bank__info">
				<div className="fi-connect-bank__info__details">
					<div className="fi-connect-bank__info__details__left">
						Fund Account :
					</div>
					<div className="fi-connect-bank__info__details__right">
						{fundName || fundNameState || '--'}
					</div>
				</div>
			</div>
		),
		[fundName, fundNameState]
	);

	const renderMainComponent = useMemo(() => {
		// Check if the user is a buyer and the investing amount equals the maximum allowed default amount
		if (isBuyer && parseFloat(investingAmount) >= MAX_DEFAULT_AMOUNT) {
			return (
				<div className="fund-loader">
					{/* Display a loading spinner to indicate fund processing is in progress */}
					<Loader />

					{/* Display the loading message centered and padded for better visual alignment */}
					<div style={{ textAlign: 'center', padding: '0 24px' }}>
						{WIRE_LOADING_MESSAGE}
					</div>
				</div>
			);
		}
		if (loader && !token)
			return (
				<div className="fund-loader">
					<Loader />
					<div style={{ textAlign: 'center' }}>{message}</div>
				</div>
			);
		if (isPerUnitPrice) {
			return <UnitPrice handleConnect={() => setIsPerUnitPrice(false)} />;
		}
		if (showConnectBank && !isCardDetailsShow)
			return (
				<ConnectBank
					headerTitle={
						headerTitle || 'Connect your bank account to fund this account'
					}
					headerSubtitle={
						headerSubtitle ||
						'You can fund this account from your multiple accounts.'
					}
					title="Amount"
					investmentDetails={renderInvestmentDetails}
					handleConnect={handleConnect}
					investingAmount={investingAmount}
					setShowConnectBank={setShowConnectBank}
					loader={loader}
					// Conditionally pass the handleBack prop if initialUnitPricingValue is truthy
					{...(initialUnitPricingValue && {
						handleBack: handleBackToUnitPrice,
					})}
				/>
			);
		else if (isCardDetailsShow && selectedPaymentMethod === 'card') {
			return <FundPaySDK setCardDetailsShow={setIsCardDetailsShow} />;
		} else
			return (
				<BankDetails addBankAccount={handleConnect} handleBack={handleBack} />
			);
	}, [
		isBuyer,
		investingAmount,
		loader,
		token,
		message,
		isPerUnitPrice,
		showConnectBank,
		isCardDetailsShow,
		headerTitle,
		headerSubtitle,
		renderInvestmentDetails,
		handleConnect,
		initialUnitPricingValue,
		handleBackToUnitPrice,
		selectedPaymentMethod,
		handleBack,
	]);

	return <div className="fi-root-wrapper">{renderMainComponent}</div>;
};
