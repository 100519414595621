export const isValidORDateGreaterThanToday = (date: any) => {
	const currentDate = new Date();
	const { day, month, year } = date ?? {};
	if (isNaN(day) || isNaN(month) || isNaN(year)) {
		return true;
	}
	const inputDate = new Date(year, month, day);
	const isValidDate =
		day >= 1 &&
		day <= 31 &&
		month > 0 &&
		month <= 12 &&
		year >= 1925 &&
		inputDate < currentDate;
	if (!isValidDate) {
		return true;
	} else return false;
};

export const validateDOB = (inputDate: any) => {
	const { year, month, day } = inputDate ?? {};
	// validate and parse the date of birth (DOB)
	const birthDateObj = new Date(year, month, day);
	const currentDate = new Date();

	// check if the birthday date has passed or not
	const isBirthdayPassed =
		currentDate.getMonth() > birthDateObj.getMonth() ||
		(currentDate.getMonth() === birthDateObj.getMonth() &&
			currentDate.getDate() >= birthDateObj.getDate());

	// calculate the age in years
	const ageDiff = currentDate.getFullYear() - birthDateObj.getFullYear();

	// calculate total age
	const age = ageDiff - (isBirthdayPassed ? 0 : 1);

	return age >= 18;
};

export const formatDate = (year: number, month: number, day: number) => {
	// Month in JavaScript's Date object is 0-indexed, so we need to subtract 1
	const date = new Date(year, month - 1, day);

	// Use the built-in methods to get the year, month, and day
	const yyyy = date.getFullYear();
	const mm = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
	const dd = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed

	return `${yyyy}${mm}${dd}`;
};

export const formatSlashedDate = (value: {
	year: number;
	month: number;
	day: number;
}) => {
	const { day, month, year } = value ?? {};
	if (!day || !month || !year) return '';
	return `${day}/${month}/${year}`;
};

export const getDateObj = (date: string | number | object) => {
	if (date && typeof date === 'string') {
		const [year, month, day] = date.split('-');
		const paylaod = {
			day,
			month,
			year,
		};
		return paylaod;
	} else if (
		typeof date === 'object' &&
		'day' in date &&
		'month' in date &&
		'year' in date
	) {
		return {
			day: parseInt(date.day as string),
			month: parseInt(date.month as string),
			year: parseInt(date.year as string),
		};
	} else {
		return date;
	}
};

export const formatToMonthDayYear = (dateObj: {
	year: number;
	month: number;
	day: number;
}) => {
	const { day: dayOfMonth, month: monthOfYear, year: fullYear } = dateObj ?? {};
	if (!dayOfMonth || !monthOfYear || !fullYear) return '';
	return `${String(monthOfYear).padStart(2, '0')}/${String(dayOfMonth).padStart(2, '0')}/${fullYear}`;
};
