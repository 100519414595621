import { useEffect, useMemo, useState } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { useSetRecoilState } from 'recoil';

import { AllRoutes } from 'routes';
import { AccessTokenState, WebComponentMetaDataState } from 'states';
import { useCheckOffline } from 'views/offline/hooks';
import { __siteMetaData } from 'utils';
import { ComponentRoot } from 'component-root';
import { useEnv } from 'hooks';
import { getSteps } from 'utils';
import 'remixicon/fonts/remixicon.css';
import './app.scss';

import { Offline } from 'views';
import { AppleUserDetailsState } from 'views/basic-information/store';

// import { GestureRecognizerClass } from 'views/palm-enroll/hooks/GestureRecognizer';

/**
 *
 * @code for qr code and sessionId
 * @handleChange this method will trigger on each current step and return current stepmeta data
 * @onComplete this method will trigger when session will be completed on success screen
 * @env for enviornment default is prod prod|stage|pp|sandbox
 */

export const App = (props: any) => {
	const setAccessToken = useSetRecoilState(AccessTokenState);
	const [loading, setLoading] = useState(props.code ? true : false);
	const setWebComponentMetaData = useSetRecoilState(WebComponentMetaDataState);
	const setBasicInfoDetails = useSetRecoilState(AppleUserDetailsState);
	const { injectUrl } = useEnv();
	const { isOnline } = useCheckOffline();

	// const loadModel = async () => {
	// 	GestureRecognizerClass.loadPalmModel();
	// 	GestureRecognizerClass.loadFaceModel();
	// };

	// useEffect(() => {
	// 	loadModel();
	// }, []);

	useEffect(() => {
		// Extracting necessary props
		const { onloaded, code } = props;

		// Determine if the current environment is within an iframe
		const isInIframe = window.top !== window.self;

		// Check if this is a web component based on the presence of 'code'
		const isWebComponent = !!code;

		// Notify parent window if running inside an iframe
		if (isInIframe) {
			(window as any).onSessionLoaded({ isLoaded: true });
		}

		// Trigger the 'onloaded' callback if provided and it's a function
		if (isWebComponent && typeof onloaded === 'function') {
			onloaded({ isLoaded: true });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setAccessToken({
			...props,
			code: props?.code ?? '',
			type: props?.onboarding_type ?? '',
			isWebComponent: !!props?.code,
		});
		if (props?.basic_information) {
			const basicInformationObject = JSON.parse(props?.basic_information);
			setBasicInfoDetails(basicInformationObject);
		}
		if (props.code) {
			injectUrl(`${__siteMetaData[props.env ?? 'prod'].appUrl}/__ENV.js`);

			setWebComponentMetaData({
				isWebComponent: !!props.code,
				env: props.env,
				apiEndPoint: __siteMetaData[props.env ?? 'prod'].endPoint,
				glareUrl: __siteMetaData[props.env ?? 'prod'].glareUrl,
				steps: getSteps(props.steps),
				biometric: props.biometric === 'false' ? false : true,
				termsCondition: props.terms_condition === 'false' ? false : true,
				fromSignup: props.from_signup === 'true' ? true : false,
				isSkipNewSession: props.skip_new_session === 'true' ? true : false,
				onComplete: props.oncomplete, // Call the `onComplete` function from props when the action is complete
			});
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}

		// if (props.qr_id) {
		// 	localStorage.setItem('type', 'qr');
		// } else {
		// 	localStorage.setItem('type', 'complex');
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const webComponentProps = useMemo(
		() => ({
			...(props.oncomplete && {
				onComplete: props.oncomplete,
			}),
			...(props.handlechange && {
				handleChange: props.handlechange,
			}),
			...(props.steps && { steps: getSteps(props.steps) }),
			...(props.biometric && {
				biometric: props.biometric === 'false' ? false : true,
			}),
			...(props.terms_condition && {
				termsCondition: props.terms_condition === 'false' ? false : true,
			}),
			...(props.from_signup && {
				fromSignup: props.from_signup === 'false' ? false : true,
			}),
		}),
		[
			props.oncomplete,
			props.handlechange,
			props.steps,
			props.biometric,
			props.terms_condition,
			props.from_signup,
		]
	);

	const renderedMainComponent = useMemo(() => {
		if (!isOnline) return <Offline />;
		//@avinashSatschel: commented By Avinash
		// if (isSpeedLow) return <InternetConnectionIssue />;
		return (
			<AllRoutes
				{...webComponentProps}
				code={props.code}
				onboarding_type={props.onboarding_type}
			/>
		);
	}, [isOnline, props.code, props.onboarding_type, webComponentProps]);

	if (loading) {
		return <ComponentRoot componentKey="default" />;
	}

	return (
		<div className="app">
			{renderedMainComponent}
			<ToastContainer // ToastContainer component for displaying notifications
				position="top-center" // Positioning of notifications
				autoClose={2000} // Auto close duration
				hideProgressBar // Hide progress bar
				closeOnClick // Close notification on click
				pauseOnHover // Pause on hover
				transition={Slide} // Transition effect
				theme="dark" // Dark theme
				limit={1} // Limit number of toasts displayed
				// style={{ width: '335px' }} // Custom style for ToastContainer
			/>
		</div>
	);
};
